import PropTypes from "prop-types";
import React from "react";

import FormattedHeadline from "@/components/FormattedHeadline";
import StickyHeader from "@/components/StickyHeader";
import FormattedDescription from "@/components/description/formatted-description";
import { getStyleObject } from "@/utils/helpers/module_style_tags";

import styles from "./index.module.scss";

const ModuleEditorialFullWidth = ({ data }) => {
  const {
    anchorTag,
    buttonText,
    headline,
    headlineMultiline,
    headlineSize,
    isWhiteBackground,
    marginBottom,
    paragraph,
    paragraphMultiline,
    stickyHeader,
    textAlign,
    url,
    useFullWidth,
  } = data;

  let textAlignClasses = "";
  let textSizeClasses = "";
  const {
    moduleEditorialFullWidth,
    textLeft,
    textRight,
    textLarge,
    textMedium,
    textSmall,
    textCenter,
    blackBg,
    whiteBg,
    topSpacer,
    nonFullWidth,
    fullWidth,
  } = styles;

  switch (textAlign) {
    case "left":
      textAlignClasses = textLeft;
      break;
    case "right":
      textAlignClasses = textRight;
      break;
    default:
      textAlignClasses = textCenter;
  }

  switch (headlineSize) {
    case "small":
      textSizeClasses = textSmall;
      break;
    case "large":
      textSizeClasses = textLarge;
      break;
    default:
      textSizeClasses = textMedium;
  }

  return (
    <>
      {stickyHeader && (
        <>
          <div className={topSpacer} />
          <StickyHeader data={stickyHeader[0].fields} />
        </>
      )}
      <section
        className={`${moduleEditorialFullWidth} ${
          isWhiteBackground ? whiteBg : blackBg
        } ${textAlignClasses} ${textSizeClasses}`}
        data-attr-scroll={anchorTag}
        style={getStyleObject({ marginBottom })}
      >
        <div className="container">
          <div className="row">
            <div className="col-16">
              <h1>
                {headlineMultiline ? (
                  <FormattedHeadline richText={headlineMultiline} />
                ) : (
                  { headline }
                )}
              </h1>
            </div>
            <div className="col-16 mx-auto" style={{ fontSize: "20px" }}>
              <div className={`${useFullWidth ? fullWidth : nonFullWidth}`}>
                {paragraphMultiline ? (
                  <FormattedDescription richText={paragraphMultiline} />
                ) : (
                  { paragraph }
                )}
              </div>
            </div>
            {buttonText && (
              <div className="col-16 mx-auto mt-4">
                <a
                  className="btn"
                  href={url ? url : "/"}
                  style={{
                    backgroundColor: "transparent",
                    border: isWhiteBackground
                      ? "1px solid #000"
                      : "1px solid #fff",
                    borderRadius: "0px",
                    color: isWhiteBackground ? "#000" : "#fff",
                    padding: "16px 44px",
                  }}
                >
                  {buttonText}
                </a>
              </div>
            )}
          </div>
        </div>
      </section>
    </>
  );
};

ModuleEditorialFullWidth.defaultPropTypes = {
  buttonText: null,
  isWhiteBackground: null,
  stickyHeader: null,
  url: "",
};

ModuleEditorialFullWidth.propTypes = {
  data: PropTypes.objectOf({
    anchorTag: PropTypes.string,
    buttonText: PropTypes.string,
    headline: PropTypes.string,
    headlineMultiline: PropTypes.object,
    headlineSize: PropTypes.string,
    isWhiteBackground: PropTypes.bool,
    marginBottom: PropTypes.string,
    paragraph: PropTypes.string,
    paragraphMultiline: PropTypes.object,
    stickyHeader: PropTypes.object,
    textAlign: PropTypes.string,
    url: PropTypes.string,
    useFullWidth: PropTypes.bool,
  }),
};

export default ModuleEditorialFullWidth;
